import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

const Main = () => {
  let [mqttdataArr, setMqttDataArr] = useState([]);
  mqttdataArr = JSON.parse(localStorage.getItem("mqttdataArr"));

  let location_list = JSON.parse(localStorage.getItem("location_list"));

  setInterval(() => {
    let mqttdataArr = JSON.parse(localStorage.getItem("mqttdataArr"));
    setMqttDataArr(mqttdataArr);
  }, 10000);

  const [imeiArr, setImeiArr] = useState([]);
  const [dataArr, setDataArr] = useState([]);
  const [ipmcbst, setipmcbst] = useState([]);
  const [contactor1, setcontactor1] = useState();
  const [contactor2, setcontactor2] = useState();
  const [contactor3, setcontactor3] = useState();
  const [opmcbst, setopmcbst] = useState();
  const [door, setdoor] = useState();
  const sendmsg = eval("(" + localStorage.getItem("sendmsg") + ")");

  const disconnectString = localStorage.getItem("disconnect");
  const disconnect = eval("(" + disconnectString + ")");
  // console.log(localStorage.disconnect);

  useEffect(() => {
    const updateData = () => {
      const imeiArr = JSON.parse(localStorage.getItem("imeiArr")) || [];
      const dataArr = JSON.parse(localStorage.getItem("dataArr")) || [];

      const ipmcbst = JSON.parse(localStorage.getItem("ipmcbst"));
      const contactor1 = JSON.parse(localStorage.getItem("contactor1"));
      const contactor2 = JSON.parse(localStorage.getItem("contactor2"));
      const contactor3 = JSON.parse(localStorage.getItem("contactor3"));
      const opmcbst = JSON.parse(localStorage.getItem("opmcbst"));
      const door = JSON.parse(localStorage.getItem("door"));

      setImeiArr(imeiArr);
      setDataArr(dataArr);
      setipmcbst(ipmcbst);
      setcontactor1(contactor1);
      setcontactor2(contactor2);
      setcontactor3(contactor3);
      setopmcbst(opmcbst);
      setdoor(door);

      // if (dataArr[5]) {
      //   console.log(
      //     dataArr[5][0],
      //     dataArr[5][1],
      //     dataArr[5][2],
      //     dataArr[5][3],
      //     dataArr[5][4]
      //   );
      // }
    };

    // updateData();
    // const intervalId = setInterval(updateData, 5000);

    // return () => clearInterval(intervalId);
  }, []);

  let imeilist = JSON.parse(localStorage.getItem("imeilist"));
  // console.log(imeilist);

  let device_ids = JSON.parse(localStorage.getItem("device_ids"));
  // console.log(device_ids);

  function handleClick(imei, deviceid, cardid) {
    const data = { imei, deviceid, cardid };
    localStorage.setItem("data", JSON.stringify(data));
  }

  const slcard = [];
  const options = [];
  for (let i = 0; i < imeilist.length; i++) {
    options.push({
      value: i,
      label: `Imei ${i}`,
    });
    slcard.push(
      <div
        key={i}
        id={`card${i}`}
        className="w-auto h-auto sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/5 sm:h-1/3 md:h-1/3 lg:h-1/3 xl:h-1/5 2xl:h-1/4 2xl:w-1/6 3xl:h-1/4 3xl:w-1/6 p-5 mt-2 shadow-cyan-600 ease-in hover:shadow-4xl transition-shadow hover:shadow-stone-900 
        max-w-sm max-h-full rounded-lg overflow-hidden shadow-lg m-2 cursor-pointer flex-grow bg-white bg-opacity-25 backdrop-filter backdrop-blur-lg border border-gray-300 border-opacity-50"
      >
        <div className="border-b-4 border-blue-900 mb-2">
          <Link
            to="/StreetLight1.js"
            onClick={() => handleClick(imeilist[i], device_ids[i], i)}
          >
            <h2 className="text-center font-bold font-sans text-xs pb-3">
              {" "}
              {location_list[i]}
            </h2>
            <h2 className="text-center font-semibold font-mono text-sm pb-3">
              {/* <Link to="/StreetLight1.js">Imei:{imeiArr[5]}</Link> */}(
              {imeilist[i]})
            </h2>
          </Link>
        </div>

        <div className="flex flex-wrap justify-center items-center">
          <p className="p-0.5 m-1 bg-gray-200 rounded-lg shadow-xl  border-2 border-blue-900 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1 text-sm ">
            VL<sub>1</sub>
            <p className="p-0.5 m-1 bg-red-400 hover:bg-red-600 hover:text-white rounded-lg shadow-lg hover:shadow-indigo-500/30 border border-red-500 hover:border-red-900 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-sm ">
              {mqttdataArr?.[i] ? mqttdataArr[i][0] : 0} V
            </p>
          </p>
          <p className="p-0.5 m-1 bg-gray-200 rounded-lg shadow-xl border-2 border-blue-900 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1 text-sm">
            VL<sub>2</sub>{" "}
            <p className="p-0.5 m-1 bg-yellow-300 hover:bg-yellow-400 hover:text-black rounded-lg shadow-xl border border-yellow-500 hover:border-yellow-500 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-sm ">
              {mqttdataArr?.[i] ? mqttdataArr[i][1] : 0} V
            </p>
          </p>
          <p className="p-0.5 m-1 bg-gray-200 rounded-lg shadow-xl border-2 border-blue-900 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1 text-sm">
            VL<sub>3</sub>{" "}
            <p className="p-0.5 m-1 bg-blue-400 hover:bg-blue-700 hover:text-white rounded-lg shadow-xl border border-indigo-500 hover:border-blue-900 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-sm">
              {mqttdataArr?.[i] ? mqttdataArr[i][3] : 0} V
            </p>
          </p>
        </div>

        <div className="flex flex-wrap justify-center items-center ">
          <p className="p-0.5 m-1 bg-gray-200 rounded-lg shadow-xl border-2 border-blue-900 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1 text-sm">
            IL<sub>1</sub>{" "}
            <p className="p-0.5 m-1 bg-red-400 hover:bg-red-600 hover:text-white rounded-lg shadow-xl border border-red-500 hover:border-red-900 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-sm">
              {mqttdataArr?.[i] ? mqttdataArr[i][8] : 0} A
            </p>
          </p>
          <p className="p-0.5 m-1 bg-gray-200 rounded-lg shadow-xl border-2 border-blue-900 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1 text-sm">
            IL<sub>2</sub>{" "}
            <p className="p-0.5 m-1 bg-yellow-300 hover:bg-yellow-400 hover:text-black rounded-lg shadow-xl border border-yellow-500 hover:border-yellow-500 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-sm">
              {mqttdataArr?.[i] ? mqttdataArr[i][9] : 0} A
            </p>
          </p>
          <p className="p-0.5 m-1 bg-gray-200 rounded-lg shadow-xl border-2 border-blue-900 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1 text-sm">
            IL<sub>3</sub>{" "}
            <p className="p-0.5 m-1 bg-blue-400 hover:bg-blue-700 hover:text-white rounded-lg shadow-xl border border-blue-500 hover:border-blue-900 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-sm ">
              {mqttdataArr?.[i] ? mqttdataArr[i][10] : 0} A
            </p>
          </p>
        </div>

        <div className="flex flex-wrap">
          <p className="p-0.5 m-1 bg-gray-200 rounded-lg shadow-xl border-2 border-blue-900 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1 text-sm">
            Power{" "}
            <p className="p-0.5 m-1 bg-green-500 hover:bg-green-600 hover:text-white rounded-lg shadow-xl border border-green-600 hover:border-green-700 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-sm">
              {mqttdataArr?.[i] ? mqttdataArr[i][183] / 10 : 0} Kw
            </p>
          </p>
          <p className="p-0.5 m-1 bg-gray-200 rounded-lg shadow-xl border-2 border-blue-900 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1 text-sm">
            Energy{" "}
            <p className="p-0.5 m-1 bg-green-500 hover:bg-green-600 hover:text-white rounded-lg  shadow-xl border border-green-600 hover:border-green-700 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-sm">
              {mqttdataArr?.[i]
                ? (mqttdataArr[i][171] * 65536 + mqttdataArr[i][172]) / 100
                : 0}{" "}
              KWh
            </p>
          </p>
        </div>

        <div className="flex flex-wrap">
          <p
            className={`flex flex-col items-center flex-1 p-0.5 m-1 justify-center  bg-gray-200 rounded-lg shadow-xl border-2 border-blue-900 transition duration-300 ease-in-out focus:outline-none text-black font-bold text-center text-sm `}
          >
            AutoManual{" "}
            <button
              className={`flex items-center flex-1 p-0.5 m-1 justify-center rounded-lg shadow-md text-sm px-2 ${
                (mqttdataArr?.[i] ? mqttdataArr[i][188] : 0) > 500
                  ? "bg-green-600 text-white hover:bg-green-500"
                  : "bg-red-600 text-white hover:bg-red-500"
              } transition-colors ease-in-out duration-200`}
            >
              {(mqttdataArr?.[i] ? mqttdataArr[i][188] : 0) > 500
                ? "ON"
                : "OFF"}
            </button>
          </p>
          {/* 188 and 189 */}
          <p
            className={`flex flex-col items-center flex-1 p-0.5 m-1 justify-center  bg-gray-200 rounded-lg shadow-xl border-2 border-blue-900 transition duration-300 ease-in-out focus:outline-none text-black font-bold text-center text-sm  `}
          >
            Contactor
            <button
              className={`flex items-center flex-1 p-0.5 m-1 justify-center rounded-lg shadow-md text-sm px-2 ${
                (mqttdataArr?.[i] ? mqttdataArr[i][189] : 0) > 500
                  ? "bg-green-600 text-white hover:bg-green-500"
                  : "bg-red-600 text-white hover:bg-red-500"
              } transition-colors ease-in-out duration-200`}
            >
              {(mqttdataArr?.[i] ? mqttdataArr[i][189] : 0) > 500
                ? "ON"
                : "OFF"}
            </button>
          </p>
        </div>
      </div>
    );
  }
  function scrolltoCard(value) {
    const element = document.getElementById(`card${value}`);
    const selectHeight = 100;
    if (element) {
      element.style.marginTop = `-${selectHeight}px`;

      element.scrollIntoView({ behavior: "smooth" });

      element.style.marginTop = "8px";
    }
  }
  const handleChange = (selectedOption) => {
    // selectedOption.scrollIntoView();
    let cardvalue = selectedOption.value;
    scrolltoCard(cardvalue);
    // cardno.scrollIntoView();
    console.log(selectedOption);
  };

  // console.log(localStorage.getItem(dataArr[5]));

  return (
    <div>
      {/* *****Navigation ***** */}
      <div className="bg-blue-800 flex flex-wrap justify-between align-middle items-center py-0.5 gap-2 sticky top-0 z-50">
        <div className="flex flex-wrap items-center">
          <div>
            <img
              src="./images/cropped.jpg"
              alt="logo"
              style={{ width: "50px", height: "50px" }}
              className="p-1"
            ></img>
          </div>
          <div className="flex flex-col text-sm font-serif text-white ">
            <h2>SANLEC</h2>
            <h2>AUTOMATION</h2>
          </div>
        </div>

        <div className="flex flex-wrap gap-2 justify-center mt-1">
          <div className="flex align-middle justify-start mb-2 items-center hover:bg-light-white rounded-md p-1">
            <img
              src=".///assets/Chart.png"
              alt="dashboard icon"
              className="w-7 h-7 text-black"
            ></img>
            <Link to="/Main">
              <h2 className="text-white text-sm flex items-center gap-x-4 cursor-pointer p-1 ">
                Dashboard
              </h2>
            </Link>
          </div>

          <div className="flex align-middle justify-start mb-2 items-center hover:bg-light-white rounded-md p-1">
            <img
              src=".///assets/User.png"
              alt="user icon"
              className="w-7 h-7"
            ></img>
            <Link to="/">
              <h2
                className="text-white text-sm flex items-center gap-x-4 cursor-pointer p-1 "
                onClick={() => {
                  const disconnectFn = localStorage.disconnect;
                  localStorage.clear();
                  localStorage.disconnect = disconnectFn;
                  disconnect();

                  // localStorage.clear();
                }}
              >
                Logout
              </h2>
            </Link>
          </div>
          <div className="mr-1">
            <Select options={options} onChange={handleChange} />
          </div>
        </div>
      </div>
      {/* *****Navigation ***** */}

      <div className="flex  flex-wrap bg-gradient-to-br from-blue-500 via-red-400 to-blue-600">
        <div className="min-h-screen  overflow-hidden">
          <div className="flex flex-wrap min-w-fit  ">{slcard}</div>
        </div>
      </div>
    </div>
  );
};

export default Main;
