import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

function ApexChart() {
  const [series, setSeries] = useState([42, 47, 52, 58, 65]);

  const options = {
    chart: {
      type: "polarArea",
    },
    labels: ["Rose A", "Rose B", "Rose C", "Rose D", "Rose E"],
    fill: {
      opacity: 1,
    },
    stroke: {
      width: 1,
      colors: undefined,
    },
    yaxis: {
      show: false,
    },
    legend: {
      position: "bottom",
    },
    plotOptions: {
      polarArea: {
        rings: {
          strokeWidth: 0,
        },
        spokes: {
          strokeWidth: 0,
        },
      },
    },
    theme: {
      monochrome: {
        enabled: false,
        shadeTo: "light",
        shadeIntensity: 0.6,
      },
    },
  };

  return (
    <div className="w-full lg:w-2/3 xl:w-1/2">
      <ReactApexChart options={options} series={series} type="polarArea" />
    </div>
  );
}

export default ApexChart;
