import "./App.css";
// import sendmsg from "./mqtt";
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  BrowserRouter,
} from "react-router-dom";

import Main from "./Main";
import Chart from "./Chart";
import Barchart from "./Barchart";
import Basicbar from "./Basicbar";
import Line from "./Line";
import Basicline from "./Basicline";
import Columns from "./Columns";
import CustomAngle from "./CustomAngle";
import Sl1 from "./Sl1";
import Login from "./Login";
import Gauge from "./Gauge";
import Best from "./Best";
import StreetLight1 from "./StreetLight1";
import Niku from "./Niku";
import EnergyBar from "./EnergyBar";
import Barfinal from "./BarFinal";
import RealTimeValue from "./RealTimeValue";
import SLanalytics from "./SLanalytics";
import Table from "./Table";

function App() {
  // var msg = { type: "control", id: 1, key: 1, value: 1 };

  return (
    <div>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Routes>
          {/* // main dashboard */}
          <Route exact path="/" element={<Login />} />
          <Route exact path="/Main" element={<Main />} />
          <Route exact path="/StreetLight1.js" element={<StreetLight1 />} />
          <Route exact path="/Table" element={<Table />} />
          {/* <Route exact path="/" element={<SLanalytics />} /> */}
          {/* <Route exact path="/" element={<RealTimeValue />} /> */}

          {/* <Route exact path="/" element={<Login />} /> */}
          {/* <Route exact path="/" element={<Chart />} /> */}
          {/* <Route exact path="/" element={<Barchart />} /> */}
          {/* <Route exact path="/" element={<Basicbar />} /> */}

          {/* <Route exact path="/" element={<Line />} /> */}

          {/* <Route exact path="/" element={<Basicline />} /> */}
          {/* <Route exact path="/" element={<Columns />} /> */}

          {/* <Route exact path="" element={<CustomAngle />} /> */}
          {/* <Route exact path="/" element={<Gauge />} /> */}
          {/* <Route exact path="/" element={<Best />} /> */}
          {/* <Route exact path="/" element={<Niku  />} /> */}
          {/* <Route exact path="/" element={<EnergyBar />} /> */}
          {/* <Route exact path="/" element={<Barfinal />} /> */}
        </Routes>
      </BrowserRouter>

      {/* <button onClick={sendmsg("vidani/ss/vio8c4b14496264", msg)}>
        Switch on
      </button> */}
    </div>
  );
}

export default App;
